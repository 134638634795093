// extracted by mini-css-extract-plugin
export var blockDescription = "payload-module--block-description--b8b24";
export var blocksContainer = "payload-module--blocks-container--3aeaf";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--be3a1";
export var blocksInRowsWithOneActiveOuterWrapper = "payload-module--blocks-in-rows-with-one-active-outer-wrapper--d00bb";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--5902a";
export var blocksRedirectingToResourcesInnerWrapper = "payload-module--blocks-redirecting-to-resources-inner-wrapper--0ad8e";
export var coloredBlocksContainer = "payload-module--colored-blocks-container--b23cd";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--60b89";
export var coloredBlocksRightPart = "payload-module--colored-blocks-right-part--b22c3";
export var commonInnerWrapper = "payload-module--common-inner-wrapper--f091b";
export var commonTitlesContainer = "payload-module--common-titles-container--1c39a";
export var companyInNumbersSection = "payload-module--company-in-numbers-section--ae057";
export var companyInNumbersTitlesContainer = "payload-module--company-in-numbers-titles-container--61ac9";
export var contactButtonTextSection = "payload-module--contact-button-text-section--a8967";
export var contactButtonTextSectionContainer = "payload-module--contact-button-text-section-container--0f3cb";
export var contactButtonTextSectionInnerWrapper = "payload-module--contact-button-text-section-inner-wrapper--3564f";
export var firstBlocksInRowsWithOneActiveSection = "payload-module--first-blocks-in-rows-with-one-active-section--b3773";
export var headerBorder = "payload-module--header-border--84a38";
export var headerInnerWrapper = "payload-module--header-inner-wrapper--cf64f";
export var headerTitleBorderWidth = "payload-module--header-title-border-width--ba1e0";
export var innerWrapperTestimonials = "payload-module--inner-wrapper-testimonials--9aeaf";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--010b3";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--41fc9";
export var opacitySliderVisibleText = "payload-module--opacity-slider-visible-text--a9f39";
export var ourApproachInnerWrapper = "payload-module--our-approach-inner-wrapper--757ef";
export var secondBlocksInRowsWithOneActiveSection = "payload-module--second-blocks-in-rows-with-one-active-section--56b96";
export var sectionUnderHeaderButton = "payload-module--section-under-header-button--dc8a5";
export var sectionUnderHeaderContentWrapper = "payload-module--section-under-header-content-wrapper--f7d2b";
export var sectionUnderHeaderInnerWrapper = "payload-module--section-under-header-inner-wrapper--0d6a6";
export var sectionUnderHeaderSliderInnerWrapper = "payload-module--section-under-header-slider-inner-wrapper--45c01";
export var sectionUnderHeaderText = "payload-module--section-under-header-text--c29e4";
export var sectionUnderHeaderToggleVisibilityButton = "payload-module--section-under-header-toggle-visibility-button--2b7a1";
export var singleBlockContainer = "payload-module--single-block-container--f665b";
export var techStackContainerWithTwoColumns = "payload-module--tech-stack-container-with-two-columns--d5b5f";
export var techStackIconsForCategory = "payload-module--tech-stack-icons-for-category--3f0cf";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--3e2de";
export var techStackRectangle = "payload-module--tech-stack-rectangle--81278";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--21535";
export var techStackSingleColumn = "payload-module--tech-stack-single-column--f91f9";
export var techStackSubtitle = "payload-module--tech-stack-subtitle--31d50";
export var titlesContainer = "payload-module--titles-container--60cf2";